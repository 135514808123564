import React from 'react';
import './underConstruction.css';


function UnderConstruction() {
  return (
    <div className="under-construction">
      <div className="card">
        <img src="https://minipg.s3.ap-south-1.amazonaws.com/images/founder_image.JPG" alt="Under Construction" className="construction-image" />
        <div className="contact-info">
          <h2>Contact Information</h2>
          <p>Founder: Omprakash Gupta</p>
          <p>Email: omchs10@gmail.com</p>
          
          <p>Message: This site is under construction. For trade related queries, please mail us.</p>
        </div>
      </div>
    </div>
  );
}

export default UnderConstruction;
