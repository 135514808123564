import logo from './logo.svg';
import './App.css';
import Navbar from './component/Navbar/navbar';
import Footer from './component/Footer/footer';
import UnderConstruction from './component/InProgress/underConstruction';

function App() {
  return (
   <div> <div><Navbar/></div>
   <div><UnderConstruction/></div>
   <div><Footer/></div>
    </div>
  );
}

export default App;
