import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 miniPG. All rights reserved.</p>
        
      </div>
    </footer>
  );
}

export default Footer;
