import React from 'react';
import './navbar.css';
import { FaHome } from 'react-icons/fa';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <FaHome className="home-icon" />
        <span className="logo">miniPG</span>
        <div className="dropdown">
          <button className="dropbtn">Services</button>
          <div className="dropdown-content">
            <a href="#link1">Book a visit</a>
            <a href="#link2">Inhouse Store</a>
            <a href="#link3">Support</a>
          </div>
        </div>
        <div className="dropdown" style={{ marginLeft: "0px" }}>

          <button className="dropbtn">Info</button>
          <div className="dropdown-content">
            <a href="#link4">About</a>
            <a href="#link5">Prices</a>
            <a href="#link6">Facilities</a>
          </div>
        </div>
      </div>
      <div className="navbar-right">
        <button className="nav-button">Login</button>
        <button className="nav-button">Contact Us</button>
      </div>
    </nav>
  );
}

export default Navbar;
